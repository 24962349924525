import Vue from 'vue'
import VueRouter from 'vue-router'
import { router } from './routes'
import App from './App.vue'
import header from './components/Header'
import "./vendors/bundle.css"
import "./assets/css/app.min.css"
import "./vendors/slick/slick.css"
import "./vendors/slick/slick-theme.css"
import "./vendors/dataTable/datatables.min.css"


// import "./vendors/bundle.js"
// import "./assets/js/app.min.js"

Vue.component('myHeader', header)

Vue.config.productionTip = false

Vue.use(VueRouter)

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
