<template>
  <div class="container">
    <!-- <div class="preloader">
      <div class="preloader-icon"></div>
    </div> -->
    <div class="form-wrapper">
    
      <!-- logo -->
      <div id="logo">
          <img class="logo" src="../assets/media/image/logo.png" alt="image">
          <img class="logo-dark" src="" alt="image">
      </div>
      <!-- ./ logo -->

      <h5>Sign in</h5>

      <!-- form -->
    <form autocomplete="off" @submit="login($event)" method="post">
          <div class="form-group">
              <input type="email" class="form-control" v-model="email" name="email" id="email" placeholder="Username or email" required autofocus>
          </div>
          <div class="form-group">
              <input type="password" class="form-control" v-model="password" name="password" id="password"  placeholder="Password" required>
          </div>
          <div class="form-group d-flex justify-content-between">
              <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" checked="" id="customCheck1">
                  <label class="custom-control-label" for="customCheck1">Remember me</label>
              </div>
              <a href="">Reset password</a>
          </div>
          <button class="btn btn-primary btn-block" type="submit">Sign in</button>
          <hr>
          <p class="text-muted">Login with your social media account.</p>
          <ul class="list-inline">
              <li class="list-inline-item">
                  <a href="#" class="btn btn-floating btn-facebook">
                      <i class="fa fa-facebook"></i>
                  </a>
              </li>
              <li class="list-inline-item">
                  <a href="#" class="btn btn-floating btn-twitter">
                      <i class="fa fa-twitter"></i>
                  </a>
              </li>
              <li class="list-inline-item">
                  <a href="#" class="btn btn-floating btn-dribbble">
                      <i class="fa fa-dribbble"></i>
                  </a>
              </li>
              <li class="list-inline-item">
                  <a href="#" class="btn btn-floating btn-linkedin">
                      <i class="fa fa-linkedin"></i>
                  </a>
              </li>
              <li class="list-inline-item">
                  <a href="#" class="btn btn-floating btn-google">
                      <i class="fa fa-google"></i>
                  </a>
              </li>
              <li class="list-inline-item">
                  <a href="#" class="btn btn-floating btn-behance">
                      <i class="fa fa-behance"></i>
                  </a>
              </li>
              <li class="list-inline-item">
                  <a href="#" class="btn btn-floating btn-instagram">
                      <i class="fa fa-instagram"></i>
                  </a>
              </li>
          </ul>
          <hr>
          <p class="text-muted">Don't have an account?</p>
          <a href="register" class="btn btn-outline-light btn-sm">Register now!</a>
      </form>
    </div>

  </div>
</template>

<script>

import Vue from 'vue';
import { axios } from "../axios"
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export default {
  data() {
    return {
      errors: [],
      email: "",
      password: "",      
    };
  },

  created: function() {
    //  this.checkCurrentLogin()
  },
  updated () {
    if (!localStorage.token && this.$route.path !== '/login') {
      this.$router.replace(this.$route.path)
    }
  },
  methods: {
    async login(e){
      e.preventDefault();
      const vm = this;
      // console.log(this.password);
      await axios
        .post("/login", { email: this.email, password: this.password })
        .then(request => 
          this.loginSuccessful(request)
        )
        .catch(() => this.loginFailed());
      },

      loginSuccessful(e) {
        console.log(e);
        if (!e.data.token) {
          this.loginFailed();
          return;
        } 
        localStorage.token = e.data.token;
        localStorage.user = JSON.stringify(e.data.user);
        this.error = false;

        this.$router.replace(this.$route.query.redirect || "/task/create");
      },

      loginFailed() {
        this.error = "Login failed!";
        delete localStorage.token;
      },
      // login: function() {
      //   this.$router.push("layout");
      // },
    }
  };


</script>

