<template>
  <div id="app">    
    <router-view />
  </div>
</template>

<script>
import Dashboard from './components/Dashboard';


export default {  
  name: 'App',
  components: {
    Dashboard
    // Remove the HelloWorld.vue 
  },
  data() {
return {
    // we are this as prop to the Dashboard component 
  center:{ 
    lat: 40.730610, 
    lng: -73.935242
    }
}
  
  }
}
</script>

<style lang="scss">

.card {
  background-color: white;
  padding: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.w-50 {
  width: 50%;
}
.form-control {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  font-size: .8125rem;
  font-weight: 400;
  padding: .47rem .75rem;
}

select.form-control {
  background-color: white;
}


button {
  border: 1px solid transparent;
  border-radius: .25rem;
  line-height: 1.5;
  padding: .47rem .75rem;
  text-align: center;
  cursor: pointer;
}


.w-100 {
  width: 100%;
}

button {
  background-color: #3452e1;
  color: white;
}

</style>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  background-color: #f6f8fa;
  font-size: 16px;
}
* {
  box-sizing: border-box;
}

.pl-0 {
  padding-left: 0 !important;
}
.p-3 {
  padding: 1rem!important;
}
.px-2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.py-2 {
  padding-top: 0.5rem!important;
  padding-bottom: 0.5rem!important;
}
.pr-2 {
  padding-right: 0.5rem!important;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1.5rem!important;
}
.m-3 {
  margin: 1rem!important;
}
.mt-3 {
  margin-top: 1rem!important;
}
.m-4 {
  margin: 1.5rem!important;
}
.mt-4 {
  margin-top: 1.5rem!important;
}

</style>
