import axios from "axios";
import { router } from './routes'

axios.defaults.baseURL = `https://pikadro.com/demo/pikadro-backend/public/api/`;

axios.interceptors.response.use(response => response, error => {    
  if (error.response.status === 401) {  
    router.push({ name: 'login'})
  }
  return Promise.reject(error);
})

export { axios }