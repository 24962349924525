<template>
  <div class="container">
    <!-- <div class="preloader">
      <div class="preloader-icon"></div>
    </div> -->
    <div class="form-wrapper">
        <h5 class="text-danger">Otp has been send on your email ID, Please check your mail</h5>
      <!-- logo -->
      <div id="logo">
          <img class="logo" src="../assets/media/image/logo.png" alt="image">
          <img class="logo-dark" src="" alt="image">
      </div>
      <!-- ./ logo -->
      <!-- form -->
     <form autocomplete="off" @submit="verificationotp($event)" method="post">
         
          <div class="form-group">
              <input type="text" class="form-control" v-model="otp" name="otp" id="otp" placeholder="Please Enter Otp For Verification" required autofocus>
              <!-- <input type="hidden" name="email" id="email" value="{{  }}"> -->
          </div>
       
          <button class="btn btn-primary btn-block" type="submit">Verified Otp</button>
          <hr>
         
      </form>
    </div>

  </div>
</template>
<script>

import Vue from 'vue';
import { axios } from "../axios"
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export default {
    data() {
      return {
        otp:"",
      }
    },
    methods: {
    async verificationotp(e) {
    
      e.preventDefault();
        
      this.checkValidations();

      const vm = this;
      console.log(VueCookies.get('email'));

      if (vm.errors.length == 0) {
        //   alert('hello');
        let formData = new FormData();
        formData.append("otp", this.otp);
        formData.append("email", VueCookies.get('email'));
        // console.log(formData);

        let url = "/otpVerified";
        // if (this.editMode) {
        //   url += `/${this.document.id}?_method=PUT`;
        // }

        await axios
          .post(url, formData)

          // .then(() => this.$router.push({ name: 'login' }))
          
          .then(function(e) {
            VueCookies.remove('email')
            vm.$router.push("/");
            // console.log(res);
          })

          .catch(error => {
            this.errors = error.response.data.errors;
            window.scrollTo(0,0);
          })
      }
    },

    checkValidations() {
      this.errors = [];

      const { otp } = this;
  
      if (!otp) {
        this.errors.push("Otp is required.");
      }

      // console.log(this.errors[0]);
    },

    parseDate(date) {
      const parsedDate = new Date(date);
      return parsedDate.toISOString().slice(0, 10);
    },
  },
    
  }
</script>
