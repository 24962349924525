<template>
    <div class="container-fluid">
             <my-header></my-header>

<div class="layout-wrapper">
   
    <div class="content-wrapper">
       
        <!-- end::navigation -->
        <div class="content-body">
            <div class="content">
                <div class="page-header">
                    <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href=index-2.html>User</a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Profile</li>
                                <li class="breadcrumb-item active" aria-current="page" v-if="user.role == 0">Admin</li>
                                <li class="breadcrumb-item active" aria-current="page" v-else-if="user.role == 1">Agent</li>
                                <li class="breadcrumb-item active" aria-current="page" v-else-if="user.role == 2">Customer</li>
                            </ol>
                        </nav>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card">
                                    <img src="../../assets/media/image/image1.jpg" class="card-img-top" alt="...">
                                    <div class="card-body text-center m-t-70-minus">
                                        <figure class="avatar avatar-xl m-b-20">
                                            <img src="../../assets/media/image/user/women_avatar1.jpg" class="rounded-circle" alt="...">
                                        </figure>
                                        <h5>{{ user.name }}</h5>
                                        <p class="text-muted" v-if="user.role == 0">Admin</p>
                                        <p class="text-muted" v-else-if="user.role == 1">Agent</p>
                                        <p class="text-muted" v-else-if="user.role == 2">Customer</p>                                        
                                        <p class="text-muted"> {{ user.phone }}</p>
                                        <p class="text-muted"> {{ user.email }}</p>
                                        <!-- <a href="#" class="btn btn-outline-primary">
                                        <i class="mr-2" data-feather="edit-2"></i> Edit Profile
                                        </a> -->
                                    </div>                                    
                                </div>                              
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                user : []
            }
        },
        mounted() {
        //    console.log('App Mounted');
            if (localStorage.getItem('user'))
                this.user = JSON.parse(localStorage.getItem('user'));
        }
    }
</script>
