import VueRouter from "vue-router";
import Vue from "vue";
import Register from "../components/register";
import VerificationOtp from "../components/Verificationotp";
import Login from "../components/Login";
import Dashboard from "../components/Dashboard";
import Profile from "../components/Admin/Profile";

import Orders from "../components/Orders/Index";
import CreateOrder from "../components/Orders/Form";
import EditOrder from "../components/Orders/Edit";

import TaskList from "../components/Tasks/Index";
import TaskMap from "../components/Tasks/Map";
import CreateTask from "../components/Tasks/Form";
import Main from "../components/Tasks/Main";
// import EditTask from "../components/Tasks/Edit";

import NotificationList from "../components/Tasks/NotificationList";

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes: [
    {
      path: "/",
      name: "login",
      component: Login,
    },
         
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
    },
    
    {
      path: "/profile",
      name: "userDetails",
      component: Profile,
    },
    
    {
      path: "/register",
      name: "register",
      component: Register,  
    },
    // Verify Otp With Email
    {
      path: "/verificationotp",
      name: "verificationotp",
      component: VerificationOtp,
    },
    
     //Orders
     {
      path: "/orders",
      name: "orders.index",
      component: Orders,
    },
    {
      path: "/order/create",
      name: "order.form",
      component: CreateOrder,
    },
    {
      path: "/order/edit/:id",
      name: "order.edit",
      component: EditOrder,
    },

    // Task
    {
      path: "/tasklist",
      name: "task.index",
      component: TaskList,
    },
    {
      path: "/main",
      name: "task.main",
      component: Main,
    },
    {
      path: "/taskmap",
      name: "task.map",
      component: TaskMap,
    },
    {
      path: "/task/create",
      name: "task.form",
      component: CreateTask,
    },

    {
      path: "/notificationlist",
      name: "task.NotificationList",
      component: NotificationList,
    },
    // {
    //   path: "/task/edit/:id",
    //   name: "task.edit",
    //   component: EditTask,
    // },
  ],
});

export { router };
