<template>
  <div class="conatiner-fluid">
    <my-header></my-header>
    <div class="feature map mt-4">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158857.7281062214!2d-0.24168183442752886!3d51.52877184100476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sin!4v1628939087589!5m2!1sen!2sin"
        style="border: 0; height: 90vh; width: 100%"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>

    <!-- left sidebar -->
    <div class="left-sidebar">
      <div class="card d-flex justify-content-center">
        <div class="card-header">
          <span class="task-card-title">Task</span>
        </div>
        <!-- nav options -->
        <ul class="nav nav-pills" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="pills-Unassigned-tab"
              data-toggle="pill"
              href="#pills-Unassigned"
              role="tab"
              aria-controls="pills-Unassigned"
              aria-selected="true"
              >2 Unassigned</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="pills-Assigned-tab"
              data-toggle="pill"
              href="#pills-Assigned"
              role="tab"
              aria-controls="pills-Assigned"
              aria-selected="false"
              >2 Assigned</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="pills-Completed-tab"
              data-toggle="pill"
              href="#pills-Completed"
              role="tab"
              aria-controls="pills-Completed"
              aria-selected="false"
              >2 Completed</a
            >
          </li>
        </ul>
        <!-- content -->
        <div class="tab-content" id="pills-tabContent p-3">
          <!-- 1st card -->
          <div
            class="tab-pane fade show active"
            id="pills-Unassigned"
            role="tabpanel"
            aria-labelledby="pills-Unassigned-tab"
          >
            <ul class="ccontent">
              <li>
                <div class="wrapp">
                  <img
                    src="/placeholder-image.png"
                    class="profile-mini-image"
                    alt="Placeholder Image"
                  />
                  <div>
                    <span class="badge pickup-badge">P</span>12:00 | Name
                    <span class="divider">---</span>
                    <span class="badge delivery-badge">D</span>13:00 | Name
                  </div>
                  <p>
                      <span class="pickup-addr">Pickup Address...</span>
                      <span class="divider">---</span>
                      <span class="delivery-addr">Delivery Address...</span>
                  </p>
                </div>
              </li>
              <li>
                <div class="wrapp">
                  <img
                    src="/placeholder-image.png"
                    class="profile-mini-image"
                    alt="Placeholder Image"
                  />
                  <div>
                    <span class="badge pickup-badge">P</span>12:00 | Name
                    <span class="divider">---</span>
                    <span class="badge delivery-badge">D</span>13:00 | Name
                  </div>
                  <p>
                      <span class="pickup-addr">Pickup Address...</span>
                      <span class="divider">---</span>
                      <span class="delivery-addr">Delivery Address...</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <!-- 2nd card -->
          <div
            class="tab-pane fade"
            id="pills-Assigned"
            role="tabpanel"
            aria-labelledby="pills-Assigned-tab"
          >
            <ul class="ccontent" :key="task.id" v-for="task in taskList">
              <li>
                <div class="wrapp">
                  <img
                    src="/placeholder-image.png"
                    class="profile-mini-image"
                    alt="Placeholder Image"
                  />
                  <div>
                    <span class="badge pickup-badge">P</span>{{ task.pickup_before }}| Name
                    <span class="divider">---</span>
                    <span class="badge delivery-badge">D</span>{{ task.del_pickup_before }} | {{ task.name }}
                  </div>
                  <p>
                      <span class="pickup-addr">Pickup Address {{ task.pickup_address }}</span>
                      <span class="divider">---</span>
                      <span class="delivery-addr">Delivery Address {{ task.del_pickup_address }}</span>
                  </p>
                </div>
              </li>              
            </ul>
          </div>
          <!-- 3nd card -->
          <div
            class="tab-pane fade"
            id="pills-Completed"
            role="tabpanel"
            aria-labelledby="pills-Completed-tab"
          >
            <ul class="ccontent">
              <li>
                <div class="wrapp">
                  <img
                    src="/placeholder-image.png"
                    class="profile-mini-image"
                    alt="Placeholder Image"
                  />
                  <div>
                    <span class="badge pickup-badge">P</span>12:00 | Name
                    <span class="divider">---</span>
                    <span class="badge delivery-badge">D</span>13:00 | Name
                  </div>
                  <p>
                      <span class="pickup-addr">Pickup Address...</span>
                      <span class="divider">---</span>
                      <span class="delivery-addr">Delivery Address...</span>
                  </p>
                </div>
              </li>
              <li>
                <div class="wrapp">
                  <img
                    src="/placeholder-image.png"
                    class="profile-mini-image"
                    alt="Placeholder Image"
                  />
                  <div>
                    <span class="badge pickup-badge">P</span>12:00 | Name
                    <span class="divider">---</span>
                    <span class="badge delivery-badge">D</span>13:00 | Name
                  </div>
                  <p>
                      <span class="pickup-addr">Pickup Address...</span>
                      <span class="divider">---</span>
                      <span class="delivery-addr">Delivery Address...</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- right sidebar -->
    <div class="right-sidebar">
      <div class="card d-flex justify-content-center">
        <div class="card-header">
          <span class="task-card-title">Driver</span>
        </div>
        <!-- nav options -->
        <ul class="nav nav-pills" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="pills-Free-tab"
              data-toggle="pill"
              href="#pills-Free"
              role="tab"
              aria-controls="pills-Free"
              aria-selected="true"
              >2 Free</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="pills-Busy-tab"
              data-toggle="pill"
              href="#pills-Busy"
              role="tab"
              aria-controls="pills-Busy"
              aria-selected="false"
              >2 Busy</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="pills-Inactive-tab"
              data-toggle="pill"
              href="#pills-Inactive"
              role="tab"
              aria-controls="pills-Inactive"
              aria-selected="false"
              >2 Inactive</a
            >
          </li>
        </ul>
        <!-- content -->
        <div class="tab-content" id="pills-tabContent p-3">
          <!-- 1st card -->
          <div
            class="tab-pane fade show active"
            id="pills-Free"
            role="tabpanel"
            aria-labelledby="pills-Free-tab"
          >
            <ul class="ccontent"  :key="task.id" v-for="task in taskList">
              <li>
                <div class="wrapp">
                  <img
                    src="/placeholder-image.png"
                    class="profile-mini-image"
                    alt="Placeholder Image"
                  />
                  <div>{{ task.name}} </div>
                  <p>+{{ task.phone}}</p>
                </div>
              </li>          
            </ul>
          </div>
          <!-- 2nd card -->
          <div
            class="tab-pane fade"
            id="pills-Busy"
            role="tabpanel"
            aria-labelledby="pills-Busy-tab"
          >
            <ul class="ccontent">
              <li>
                <div class="wrapp">
                  <img
                    src="/placeholder-image.png"
                    class="profile-mini-image"
                    alt="Placeholder Image"
                  />
                  <div>Driver Name</div>
                  <p>+91 235878632</p>
                </div>
              </li>
              <li>
                <div class="wrapp">
                  <img
                    src="/placeholder-image.png"
                    class="profile-mini-image"
                    alt="Placeholder Image"
                  />
                  <div>Driver Name</div>
                  <p>+91 235878632</p>
                </div>
              </li>
            </ul>
          </div>
          <!-- 3nd card -->
          <div
            class="tab-pane fade"
            id="pills-Inactive"
            role="tabpanel"
            aria-labelledby="pills-Inactive-tab"
          >
            <ul class="ccontent">
              <li>
                <div class="wrapp">
                  <img
                    src="/placeholder-image.png"
                    class="profile-mini-image"
                    alt="Placeholder Image"
                  />
                  <div>Driver Name</div>
                  <p>+91 235878632</p>
                </div>
              </li>
              <li>
                <div class="wrapp">
                  <img
                    src="/placeholder-image.png"
                    class="profile-mini-image"
                    alt="Placeholder Image"
                  />
                  <div>Driver Name</div>
                  <p>+91 235878632</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import { axios } from "@/axios";
    
    export default {
      created() {
        this.get();
      },
      data() {
        return {
          taskList: [],
          // user:[],
        };
      },
      methods: {
        get() {
          let user = JSON.parse(localStorage.getItem('user'));
          console.log(user.id);
          axios.post(`/taskList/${user.id}`).
          then(response => {  
            this.taskList = response.data.data;  
            // console.log('then');
            // console.log(response.data);  
          })  
          .catch(e => {  
            // console.log('catch');
            console.log(e);  
          });
          // then(({ response }) => {
          //   console.log(response);
          //   this.tasks = response.data.data;
          // });
        },      
      },

      parseDate(date) {
      const parsedDate = new Date(date);
      return parsedDate.toISOString().slice(0, 10);
    },
    };
  
   
</script>


<style lang="scss">
// @import "https://fonts.googleapis.com/css?family=Montserrat:400,700|Roboto:100,400,500,700,900";

.feature.map iframe {
  width: 100%;
  border: 0;
  top: 50px;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(99%);
  -webkit-backface-visibility: hidden;
}

.left-sidebar {
  position: absolute;
  width: 25%;
  top: 70px;
  height: 90vh;
  left: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.left-sidebar .card {
  padding: 0;
  border: none;
  border-radius: 0.5rem;
  font-family: "Roboto", sans-serif;
  box-shadow: none;
}

.left-sidebar .card .card-header {
  background-color: #2c3e50;
  padding: 15px 0;
  border-radius: 0;
  border-bottom: none;
}

.left-sidebar span.task-card-title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: transparent;
  color: #fff;
  letter-spacing: 1.5px;
}

.left-sidebar .card img.profile-mini-image {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
  background-color: #dcdcdc;
  margin-right: 10px;
}

.left-sidebar a.active {
  border-bottom: 3px solid #2c3e50;
}

.left-sidebar .nav-item {
  width: 33.3%;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.left-sidebar .nav-link {
  color: rgb(110, 110, 110);
  font-weight: 500;
}

.left-sidebar span.pickup-badge {
  background-color: rgb(0, 126, 0);
  margin: 0 3px 0 0;
  padding: 4px 6px;
  border-radius: 25px;
}
.left-sidebar span.delivery-badge {
  background-color: rgb(0, 126, 0);
  margin: 0 3px 0 3px;
  padding: 4px 6px;
  border-radius: 25px;
}

.left-sidebar span.divider{
    background-color: transparent;
    color: #2c3e50;
    margin: 0;
}

.left-sidebar p span.pickup-addr{
    background-color: transparent;
    color: #2c3e50;
    margin: 0;
    padding: 0;
    font-size: 12px;
    letter-spacing: .3px;
    text-transform: capitalize;
    line-height: 1;
}

.left-sidebar p span.delivery-addr{
    background-color: transparent;
    color: #2c3e50;
    margin: 0;
    padding: 0;
    font-size: 12px;
    letter-spacing: .3px;
    text-transform: capitalize;
    line-height: 1;
}

.left-sidebar .nav-link:hover {
  color: #000;
}

.left-sidebar .nav-pills {
  background-color: #dcdcdc;
}

.left-sidebar .tab-content {
  height: 78vh;
  overflow-y: scroll;
}

.left-sidebar .nav-pills .nav-link.active {
  color: black;
  background-color: #dcdcdc;
  border-radius: 0;
  font-weight: 600;
  padding: 12px;
}

.left-sidebar .nav-pills .nav-link {
  letter-spacing: 0.5px;
  padding: 12px;
}

.left-sidebar .form-control {
  background-color: rgb(241, 243, 247);
  border: none;
}

.left-sidebar span {
  margin-left: 0.5rem;
  padding: 1px 10px;
  color: white;
  background-color: rgb(143, 143, 143);
  border-radius: 4px;
  font-weight: 600;
}

.left-sidebar .third {
  padding: 0 1.5rem 0 1.5rem;
}

.left-sidebar label {
  font-weight: 500;
  color: rgb(104, 104, 104);
}

.left-sidebar .btn-success {
  float: right;
}

.left-sidebar .form-control:focus {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset,
    0px 0px 7px rgba(0, 0, 0, 0.2);
}

.left-sidebar select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.left-sidebar ul {
  list-style: none;
  padding-inline-start: 0;
}

.left-sidebar .ccontent li .wrapp {
  padding: 15px 10px;
  border-bottom: 1px solid #b7b7b7;
  cursor: pointer;
}

.left-sidebar .ccontent li .wrapp div {
  font-weight: 600;
}

.left-sidebar .ccontent li .wrapp p {
  margin: 0;
}

.left-sidebar .ccontent li:hover {
  background-color: #2c3e50;
  color: white;
}

.left-sidebar .ccontent li:hover span {
  color: white;
}

.left-sidebar .addinfo {
  padding: 0 1rem;
}

// right sidebar
.right-sidebar {
  position: absolute;
  width: 25%;
  top: 70px;
  height: 90vh;
  right: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.right-sidebar .card {
  padding: 0;
  border: none;
  border-radius: 0.5rem;
  font-family: "Roboto", sans-serif;
  box-shadow: none;
}

.right-sidebar .card .card-header {
  background-color: #2c3e50;
  padding: 15px 0;
  border-radius: 0;
  border-bottom: none;
}

.right-sidebar span.task-card-title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: transparent;
  color: #fff;
  letter-spacing: 1.5px;
}

.right-sidebar .card img.profile-mini-image {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease;
  background-color: #dcdcdc;
  margin-right: 10px;
}

.right-sidebar a.active {
  border-bottom: 3px solid #2c3e50;
}

.right-sidebar .nav-item {
  width: 33.3%;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.right-sidebar .nav-link {
  color: rgb(110, 110, 110);
  font-weight: 500;
}

.right-sidebar .nav-link:hover {
  color: #000;
}

.right-sidebar .nav-pills {
  background-color: #dcdcdc;
}

.right-sidebar .nav-pills .nav-link.active {
  color: black;
  background-color: #dcdcdc;
  border-radius: 0;
  font-weight: 600;
  padding: 12px;
}

.right-sidebar .nav-pills .nav-link {
  letter-spacing: 0.5px;
  padding: 12px;
}

.right-sidebar .form-control {
  background-color: rgb(241, 243, 247);
  border: none;
}

.right-sidebar .tab-content {
  height: 78vh;
  overflow-y: scroll;
}

.right-sidebar span {
  margin-left: 0.5rem;
  padding: 1px 10px;
  color: white;
  background-color: rgb(143, 143, 143);
  border-radius: 4px;
  font-weight: 600;
}

.right-sidebar .third {
  padding: 0 1.5rem 0 1.5rem;
}

.right-sidebar label {
  font-weight: 500;
  color: rgb(104, 104, 104);
}

.right-sidebar .btn-success {
  float: right;
}

.right-sidebar .form-control:focus {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset,
    0px 0px 7px rgba(0, 0, 0, 0.2);
}

.right-sidebar select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.right-sidebar ul {
  list-style: none;
  padding-inline-start: 0;
}

.right-sidebar .ccontent li .wrapp {
  padding: 15px 10px;
  border-bottom: 1px solid #b7b7b7;
  cursor: pointer;
}

.right-sidebar .ccontent li .wrapp div {
  font-weight: 600;
}

.right-sidebar .ccontent li .wrapp p {
  margin: 0;
}

.right-sidebar .ccontent li:hover {
  background-color: #2c3e50;
  color: white;
}

.right-sidebar .addinfo {
  padding: 0 1rem;
}
</style>