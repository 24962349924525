<template>
  <div class="container-fluid">
     <my-header></my-header>

     <div class="mt-5 p-4">
      <!-- <div class="d-flex" style="justify-content: space-between">
        <h3>orders</h3>
        <button @click="add()" class="add-more">Add</button>
      </div> -->

        <div class="card mt-5 radius shadow-lg">
          <div class="card-header">
              <span class="card-title h4">Order List</span>
              <button @click="add()" class="btn btn-primary float-right text-white">Create New Order</button>
          </div>
          <div class="card-body">
              <div class="table-responsive" tabindex="9" style="overflow: hidden; outline: none;">
                  <table class="table text-center">
                      <thead class="thead-dark">
                          <tr>
                              <th>#</th>
                              <th>User Name</th>
                              <th>Product Name</th>
                              <th>Location</th>
                              <th>Product Price</th>
                              <th>Qty</th>
                              <th>Status</th>
                              <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                           <tr :key="order.id" v-for="order in orderList">
                            <td>{{ order.id }}</td>
                            <td>{{ order.username }}</td>
                            <td>{{ order.product_name }}</td>
                            <td>{{ order.location_name }}</td>
                            <td>{{ order.product_price }}</td>
                            <td>{{ order.qty }}</td>
                            <td>{{ order.status }}</td>
                            <td>
                              <a href="#" class="badge badge-primary mr-2 text-white" @click="edit(order)">Edit</a>
                              <a href="#" class="badge badge-danger text-white" @click="deleteDoc(order)">Delete</a>
                            </td>
                          </tr>                      
                      </tbody>
                  </table>
              </div>
            
          </div>
        </div>    
    </div>
  </div>
    
</template>
<script>
import { axios } from "@/axios";

export default {
  created() {
    this.get();
  },
  data() {
    return {
      orderList: [],
    };
  },
  methods: {
    get() {
      axios.get("/orderList").
      then(response => {  
        this.orderList = response.data.data;  
        // console.log(response.data.data);  
      })  
      .catch(e => {  
        console.log(e);  
      });
      // then(({ response }) => {
      //   console.log(response);
      //   this.orders = response.data.data;
      // });
    },
    add() {
      this.$router.push({ name: "order.form" });
    },
    edit(order) {
      this.$router.push({ name: "order.edit", params: { id: order.id } });
    },
    deleteDoc(order) {
      axios.delete(`orders/${order.id}`).then(() => this.get());
    },
    parseDate(date) {
      const parsedDate = new Date(date);
      return parsedDate.toISOString().slice(0, 10);
    },
  },
};
</script>

<style lang="scss">
.d-flex {
  display: flex;
}
.table {
  overflow-x: scroll;
  table {
    width: fit-content;

    th,
    td {
      padding: 16px;
    }

    tr {
      border-bottom: 1px solid black;
    }
  }
}
</style>

<style lang="scss" scoped>
.add-more {
  width: 135px;
  height: 46px;
}
</style>
