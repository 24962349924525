<template>
  <div class="container-fluid">
    <my-header></my-header>      
    <div class="mt-5 p-4">
      <div v-show="Object.keys(errors).length">
        <ul>
          <li style="color: red" :key="error" v-for="error in errors">
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="row p-4">
          <div class="col-md-12">
              <div class="card">
                  <div class="card-body">
                    <div class="row">
                        <div class="col-md-8">
                          <form @submit="save($event)" method="post">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">User ID</label>
                                    <input type="text" class="form-control" v-model="user_id" name="user_id" placeholder="Enter User Id">
                            
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Product ID</label>
                                    <input type="text" class="form-control" v-model="product_id"  name="product_id" placeholder="Enter Product ID">
                                  
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Location</label>
                                    <input type="text" class="form-control" v-model="location_id"  name="location_id" placeholder="Enter Location">
                                  </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Price</label>
                                    <input type="text" class="form-control" v-model="product_price"  name="product_price" placeholder="Enter Price">
                                  </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">QTY</label>
                                    <input type="text" class="form-control" v-model="qty"  name="qty" placeholder="Enter Qty">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Status</label>
                                    <input type="text" class="form-control" v-model="status"  name="status" placeholder="Enter Status">
                                    
                                </div>    
                                <div class="d-flex mt-3" style="justify-content: center;">
                                  <button type="submit" class="">Save</button>
                                  <button type="button" style="background-color: red; margin-left: 3px">
                                    Cancel
                                  </button>
                                </div>   
                            </form>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// import { axios } from "@/axios";
import Vue from 'vue'
import { axios } from "../../axios"
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)


export default {
  created () {
    // this.$cookies.set("email", this.email);
  },
  data() {
    return {
      errors: [],
      order: {       
        user_id: "",
        product_id: "",
        location_id: "",
        product_price: "",
        qty: "",
        status: "",
      },
      
    };
  },
  methods: {
    async save(e) {
    
      e.preventDefault();

      // this.checkValidations();

      const vm = this;
      // console.log(this.errors.length);

      if (vm.errors.length == 0) {
        let formData = new FormData();
        formData.append("user_id", this.user_id);
        formData.append("product_id", this.product_id);
        formData.append("location_id", this.location_id);
        formData.append("product_price", this.product_price);
        formData.append("qty", this.qty);
        formData.append("status", this.status);
        // console.log(formData);

        VueCookies.set('user_id',this.user_id);

        let url = "/createOrder";
        // if (this.editMode) {
        //   url += `/${this.document.id}?_method=PUT`;
        // }

        await axios
          .post(url, formData)

          // .then(() => this.$router.push({ name: 'login' }))
          
          .then(function(e) {
            vm.$router.push("/orders");
            // console.log(res);
          })

          .catch(error => {
            this.errors = error.response.data.errors;
            window.scrollTo(0,0);
          })
      }
    },

    // checkValidations() {
    //   this.errors = [];

    //   const { name, email, phone, password } = this;
  
    //   if (!name) {
    //     this.errors.push("Name is required.");
    //   }

    //   if (!email) {
    //     this.errors.push("Email is required.");
    //   }

    //   if (!phone) {
    //     this.errors.push("Phone is required.");
    //   }

    //   if (!password) {
    //     this.errors.push("Password is required.");
    //   }
    //   // console.log(this.errors[0]);
    // },

    parseDate(date) {
      const parsedDate = new Date(date);
      return parsedDate.toISOString().slice(0, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.inp {
  border: none;
  height: 50px;
  padding: 5px 10px;
  outline: none;
}

[placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.4s 0.4s ease;
  text-indent: -100%;
  opacity: 1;
}
</style>
