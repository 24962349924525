<template>
  <div>
    <div class="d-flex" style="justify-content: space-between;">
      <h3>Edit order</h3>
    </div>

    <Form :order="this.order" :editMode="1"></Form>
  </div>
</template>

<script>
import { axios } from "@/axios";
import Form from "./Form";

export default {
  components: {
    Form,
  },
  created() {
    axios
      .get(`updateOrder/${this.$route.params.id}`)
      .then(({ data }) => (this.order = data.data));
  },
  data() {
    return {
      order: {
        user_id: "",
        product_id: "",
        location_id: "",
      },
    };
  },
};
</script>
