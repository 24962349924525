<template>
  <div class="container-fluid">
     <my-header></my-header>
     <div class="card radius shadow-lg">            
           <div class="card-header">
            <span class="card-title h5">Task List</span>
             <button @click="add()" class="btn add-btn float-right text-white">
                Create New task
              </button>
              <table id="example" class="table table-striped table-bordered mt-5" style="width: 100%">
                  <thead>
                      <tr>
                        <th class="cb">
                          <input type="checkbox" onclick="checkAll(this)" />
                        </th>
                        <th>NOTIFICATION ID</th>
                        <th>AGENT NAME</th>
                        <th>ORDER ID</th>
                        <th>MESSAGE</th>
                        <!-- <th>LINK</th> -->
                        <th>ACTION</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr :key="notification.id" v-for="notification in notificaionList">                        
                        <td><input type="checkbox" name="" /></td>
                        <td>{{ notification.id }}</td>
                        <td>{{ notification.agent_name }}</td>
                        <td>{{ notification.order_id }}</td>
                        <td>{{ notification.message }}</td>
                        <!-- <td>{{ notification.link }}</td> -->
                        <td>
                          <span><a href="#" @click="edit(task)" data-toggle="tooltip" data-placement="top" title="Edit Task"><i data-toggle="tooltip" data-placement="top" title="Edit Task" class="fa fa-pencil px-1"></i></a></span>
                          <span><a href="#"  @click="deleteDoc(task)" data-toggle="tooltip" data-placement="top" title="Delect Task"><i data-toggle="tooltip" data-placement="top" title="Delect Task" class="fa fa-trash px-1"></i></a></span>
                          <span><a href="#" data-toggle="tooltip" data-placement="top" title="Assign Task"><i data-toggle="tooltip" data-placement="top" title="Assign Task" class="fa fa-share px-1"></i></a></span>
                        </td>  
                      </tr>                      
                  </tbody>
              </table>
          </div>
      </div>
  </div>
    
</template>
<script>
import { axios } from "@/axios";

export default {
  created() {
    this.get();
  },
  data() {
    return {
      notificaionList: [],
      // user:[],
    };
  },
  methods: {
    get() {
      let user = JSON.parse(localStorage.getItem('user'));
    //   console.log(user.id);
      axios.post(`/notificaionList/${user.id}`).
      then(response => {  
        this.notificaionList = response.data.data;  
        // console.log('then');
        // console.log(response.data);  
      })  
      .catch(e => {  
        // console.log('catch');
        console.log(e);  
      });
      // then(({ response }) => {
      //   console.log(response);
      //   this.notifications = response.data.data;
      // });
    },
    add() {
      this.$router.push({ name: "notification.form" });
    },
    edit(notification) {
      this.$router.push({ name: "notification.edit", params: { id: notification.id } });
    },
    deleteDoc(notification) {
      axios.delete(`notifications/${notification.id}`).then(() => this.get());
    },
    parseDate(date) {
      const parsedDate = new Date(date);
      return parsedDate.toISOString().slice(0, 10);
    },
  },
};
</script>
<style lang="scss" scoped>

@import "https://fonts.googleapis.com/css?family=Montserrat:400,700|Roboto:100,400,500,700,900";

body{
  font-family: "Roboto", sans-serif;
}

table.dataTable thead .sorting {
  // background-image: url("https://datatables.net/media/images/sort_both.png") !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

table.dataTable thead .sorting_asc {
  // background-image: url("https://datatables.net/media/images/sort_asc.png") !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

table.dataTable thead .sorting_desc {
  // background-image: url("https://datatables.net/media/images/sort_desc.png") !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

table.dataTable thead .cb {
  background-image: none !important;
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
    right: 0.5em;
    content: none;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
    right: 1em;
    content: none;
}

table.dataTable thead th{
  height: 35px;
  padding: 8px 10px;
  vertical-align: middle;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}

table.dataTable tbody tr td{
  height: 35px;
  vertical-align: middle;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    letter-spacing: .5px;
} 

.card{
  padding: 50px;
}

.card-heading{
  padding: 8px;
}

.card .card-header{
  border-bottom: none;
  padding: 0;
  margin-bottom: 20px;
}

.card .card-title{
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

div.dataTables_wrapper div.dataTables_length select {
    width: auto;
    display: inline-block;
    border-radius: 5px;
}

.btn.add-btn{
  border-radius: 5px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1.5px;
  background-color: #000 ;
  border: none;
}

table.dataTable thead th, table.dataTable thead td{
  border-bottom: 1px solid #fff;
}

table.dataTable.no-footer{
  border-bottom: 1px solid #dee2e6;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
  font-size: 10px;
  background-color: #000 !important;
  border: none !important;
}

</style>

