<template>
  <div class="container-fluid">
    <my-header></my-header>  
    <div class="feature map mt-4">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158857.7281062214!2d-0.24168183442752886!3d51.52877184100476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sin!4v1628939087589!5m2!1sen!2sin"
        style="border: 0; height: 90vh; width: 100%"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>  
    <div v-show="Object.keys(errors).length">
      <ul>
        <li style="color: red" :key="error" v-for="error in errors">
          {{ error }}
        </li>
      </ul>
    </div>
      
    <div class="task-form mt-4">
      <div class="conatiner-fluid"> 
        <form class="task-main-form" @submit="save($event)" method="post">
            <label class="m-heading">Task For Pickup</label>
               <div class="row">
                <div class="col-md-6 col-lg-6 col-12">                                
                  <div class="input-group input-group-icon">
                      <input type="text" v-model="name" name="name" placeholder="Full Name *">
                      <div class="input-icon"><i class="fa fa-user"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                      <input type="tel"  v-model="phone"  name="phone" placeholder="Contact Number *">
                      <div class="input-icon"><i class="fa fa-phone"></i></div>            
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="text"  v-model="email"  name="email" placeholder="Email Adress *">
                    <div class="input-icon"><i class="fa fa-envelope"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                     <input type="text"  v-model="order_id"  name="order_id" placeholder="Order ID">
                    <div class="input-icon"><i class="fa fa-cube"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="text"  v-model="pickup_address"  name="pickup_address" placeholder="Pickup Address *">   
                    <div class="input-icon"><i class="fa fa-map-marker"></i></div>
                  </div>
                  <label class="label-switch switch-primary pt-3 pb-3">
                  <input type="checkbox" class="switch switch-bootstrap status pl-3" name="p-status" id="p-status"
                  value="1" />
                  <span class="lable">&#160;&#160;Enable Coordinates For Pickup</span></label>
                </div>
                <div class="col-md-6 col-lg-6 col-12">
                  <div class="input-group input-group-icon">
                    <input type="text" v-model="location" name="location" id="p-latitude" class="p-co-ordinates" placeholder="Latitude" disabled />
                    <div class="input-icon"><i class="fa fa-map-marker"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="text"  v-model="location" name="location" id="p-longitude" class="p-co-ordinates" placeholder="Longitude" disabled />
                    <div class="input-icon"><i class="fa fa-map-marker"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="text" id="date-time" v-model="pickup_before" name="pickup_before" placeholder="Pickup Before *" />
                    <div class="input-icon"><i class="fa fa-calendar"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="text" name="description"  v-model="description"  id="description" placeholder="Description *" >
                    <div class="input-icon"><i class="fa fa-pencil"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                     <input type="file" name="image" ref="image" v-on:change="handleFileUpload()"  placeholder="Click to browse or drop an image file" />
                    <div class="input-icon"><i class="fa fa-upload"></i></div>
                  </div>
                </div>
              </div>
               <!-- HTML FOR Task Delivery -->
              <label class="m-heading">Task For Delivery</label>
              <div class="row">
                <div class="col-md-6 col-lg-6 col-12">
                  <div class="input-group input-group-icon">
                    <input type="text" v-model="del_name" name="del_name" placeholder="Full Name *" />
                    <div class="input-icon"><i class="fa fa-user"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="tel" v-model="del_phone" name="del_phone" placeholder="Contact Number *" />
                    <div class="input-icon"><i class="fa fa-phone"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="email" v-model="del_email" name="del_email" placeholder="Email Adress *" />
                    <div class="input-icon"><i class="fa fa-envelope"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="text" v-model="del_order_id" name="del_order_id" placeholder="Order ID *" />
                    <div class="input-icon"><i class="fa fa-cube"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="text" v-model="del_pickup_address" name="del_pickup_address" placeholder="Pickup Address *" />
                    <div class="input-icon"><i class="fa fa-map-marker"></i></div>
                  </div>
                  <label class="label-switch switch-primary pt-3 pb-3">
                    <input
                      type="checkbox" class="switch switch-bootstrap status pl-3" id="d-status" value="1" />
                    <span class="lable ">&#160;&#160;Enable Coordinates For Delivery</span></label
                  >
                </div>
                <div class="col-md-6 col-lg-6 col-12">
                  <div class="input-group input-group-icon">
                    <input type="text"  v-model="del_location" name="del_location"  id="d-latitude" class="d-co-ordinates" placeholder="Latitude" disabled />
                    <div class="input-icon"><i class="fa fa-map-marker"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="text" v-model="del_location" name="del_location" id="d-longitude" class="d-co-ordinates" placeholder="Longitude" disabled />
                    <div class="input-icon"><i class="fa fa-map-marker"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="datetime-local" v-model="del_pickup_before" name="del_pickup_before" id="date-time" placeholder="Pickup Before *" />
                    <div class="input-icon"><i class="fa fa-calendar"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="text" v-model="del_description" name="del_description" placeholder="Description *" />
                    <div class="input-icon"><i class="fa fa-pencil"></i></div>
                  </div>
                  <div class="input-group input-group-icon">
                    <input type="file" name="del_image" ref="del_image" v-on:change="handleFileUpload()"  placeholder="Click to browse or drop an image file" />
                    <div class="input-icon"><i class="fa fa-upload"></i></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-lg-12 col-12">
                  <div class="input-group input-group-icon">
                    <select v-model="agent_id" name="agent_id" id="agent_id">
                      <option value="" selected>Assign Automatically</option>
                      <option  :key="agent.id" v-for="agent in agentList" v-bind:value="agent.id">
                        {{ agent.name }}
                      </option>
                    </select>                    
                  </div> 
                  <button type="submit" class="btn create-task-btn" >Create Task</button>  
                </div>
              </div>  
        </form>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// import { axios } from "@/axios";
import Vue from 'vue'
import { axios } from "../../axios"
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)


export default {
  
  created() {
    this.get();
  },

  data() {
    return {
      errors: [],
      agentList: [],
      task: {  
        agent_id:"",
        location:"",
        name: "",
        phone: "",
        email:"",
        order_id:"",
        pickup_address: "",
        pickup_before: "",
        description:"",
        image:"",
        del_location:"",
        del_name: "",
        del_phone: "",
        del_email:"",
        del_order_id:"",
        del_pickup_address: "",
        del_pickup_before: "",
        del_description:"",
        del_image:"",
        status_id: 3,
      },
      
    };
  },
  
  methods: {
    get() {
      axios.get("/agentList").
      then(response => {  
        this.agentList = response.data;  
        console.log('Then');
        console.log(response.data);  
      })  
      .catch(e => {  
        console.log('catch');
        console.log(e);  
      });      
    },

    async save(e) {
    
      e.preventDefault();

      // this.checkValidations();

      const vm = this;
      // console.log(this.errors.length);

      if (vm.errors.length == 0) {
        let formData = new FormData();
        formData.append("agent_id", this.agent_id);
        formData.append("location", this.location);
        formData.append("name", this.name);
        formData.append("phone", this.phone);
        formData.append("email", this.email);
        formData.append("order_id", this.order_id);       
        formData.append("pickup_address", this.pickup_address);
        formData.append("pickup_before", this.pickup_before);
        formData.append("description", this.description);
        formData.append("image", this.image);
        
        formData.append("del_location", this.del_location);
        formData.append("del_name", this.del_name);
        formData.append("del_phone", this.del_phone);
        formData.append("del_email", this.del_email);
        formData.append("del_order_id", this.del_order_id);       
        formData.append("del_pickup_address", this.del_pickup_address);
        formData.append("del_pickup_before", this.del_pickup_before);
        formData.append("del_description", this.del_description);
        formData.append("del_image", this.del_image);
        formData.append("status_id", 1);
        console.log(formData);

        VueCookies.set('agent_id',this.agent_id);

        let url = "/createTask";
        // if (this.editMode) {
        //   url += `/${this.document.id}?_method=PUT`;
        // }

        // await axios
        //   .post(url, formData)

         await axios
          .post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          

          // .then(() => this.$router.push({ name: 'login' }))
          
          .then(function(e) {
            vm.$router.push("/tasklist");
            console.log('Then');
            // console.log(res);
          })

          .catch(error => {
            this.errors = error.response.data.errors;
            console.log('Catch');
            window.scrollTo(0,0);
          })
      }
    },
    handleFileUpload() {
      this.task.image = this.$refs.image.files[0];
    },
  
    parseDate(date) {
      const parsedDate = new Date(date);
      return parsedDate.toISOString().slice(0, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.inp {
  btask: none;
  height: 50px;
  padding: 5px 10px;
  outline: none;
}

[placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.4s 0.4s ease;
  text-indent: -100%;
  opacity: 1;
}
</style>
