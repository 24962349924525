<template>
   
  <div class="container">
    
    <div class="form-wrapper">    

      <!-- logo -->
      <div id="logo">
          <img class="logo" src="../assets/media/image/logo.png" alt="image">
          <img class="logo-dark" src="" alt="image">
      </div>
      <!-- ./ logo -->
    
    <h5>Create account</h5>

    <!-- <div v-show="Object.keys(errors).length">
      <ul>
        <li style="color: red"  :key="error" v-for="error in errors">{{ error }}</li>
      </ul>
    </div> -->

    <!-- form -->
    <h3>{{message}}</h3>
    <form @submit="register($event)" method="post">
        <div class="form-group text-left">
            <input type="text" class="form-control mb-0" v-model="name" placeholder="Name" name="name"  autofocus>
            <span class="text-danger" v-show="Object.keys(errors).length">{{ errors[0] }}</span>
        </div>
        
        <div class="form-group text-left">
           <input type="email" class="form-control mb-0" v-model="email" placeholder="Email" name="email" autofocus>
           <span class="text-danger" v-show="Object.keys(errors).length">{{ errors[1] }}</span>
        </div>
        <div class="form-group text-left">
            <input type="number" class="form-control mb-0" v-model="phone" placeholder="Phone" name="phone" autofocus>
            <span class="text-danger" v-show="Object.keys(errors).length">{{ errors[2] }}</span>
        </div>
        <div class="form-group text-left">
           <input type="password" class="form-control mb-0" v-model="password" placeholder="Password" name="password" autofocus>
           <span class="text-danger" v-show="Object.keys(errors).length">{{ errors[3] }}</span>
        </div>
        <button class="btn btn-primary btn-block" type="submit">Register</button>
        <hr>
        <p class="text-muted">Already have an account?</p>
        <a href="/" class="btn btn-outline-light btn-sm">Sign in!</a>
    </form>
    <!-- ./ form -->


</div>
  </div>
</template>

<script>
/* eslint-disable */
// import { axios } from "@/axios";
import Vue from 'vue'
import { axios } from "../axios"
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)


export default {
  created () {
    // this.$cookies.set("email", this.email);
  },
  data() {
    return {
      errors: [],
      user: {       
        name: "",
        email: "",
        phone: "",
        password: "",
      },
      
    };
  },
  methods: {
    async register(e) {
    
      e.preventDefault();

      this.checkValidations();

      const vm = this;
      // console.log(this.errors.length);

      if (vm.errors.length == 0) {
        let formData = new FormData();
        formData.append("name", this.name);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("password", this.password);
        // console.log(formData);

        VueCookies.set('email',this.email);

        let url = "/register";
        // if (this.editMode) {
        //   url += `/${this.document.id}?_method=PUT`;
        // }

        await axios
          .post(url, formData)

          // .then(() => this.$router.push({ name: 'login' }))
          
          .then(function(e) {
            vm.$router.push("/verificationotp");
            // console.log(res);
          })

          .catch(error => {
            this.errors = error.response.data.errors;
            window.scrollTo(0,0);
          })
      }
    },

    checkValidations() {
      this.errors = [];

      const { name, email, phone, password } = this;
  
      if (!name) {
        this.errors.push("Name is required.");
      }

      if (!email) {
        this.errors.push("Email is required.");
      }

      if (!phone) {
        this.errors.push("Phone is required.");
      }

      if (!password) {
        this.errors.push("Password is required.");
      }
      // console.log(this.errors[0]);
    },

    parseDate(date) {
      const parsedDate = new Date(date);
      return parsedDate.toISOString().slice(0, 10);
    },
  },
};
</script>