<template>
    
    <div class="container-fluid">
        <my-header></my-header>    

    <!-- begin::main -->
    <div class="layout-wrapper">      
        <div class="content-wrapper">       
            <!-- end::navigation -->
            <div class="content-body">
                <div class="content">                    
                    <div class="page-header">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href=index-2.html>Pikadro</a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
                                <li class="breadcrumb-item active" aria-current="page" v-if="user.role == 0">Admin</li>
                                <li class="breadcrumb-item active" aria-current="page" v-else-if="user.role == 1">Agent</li>
                                <li class="breadcrumb-item active" aria-current="page" v-else-if="user.role == 2">Customer</li>
                            </ol>
                        </nav>
                    </div>
                    <div>
                        <h5>Welcome {{ user.name }}</h5>
                    </div>
                </div>
                 <div id="map">
                <!--In the following div the HERE Map will render-->
                    <div id="mapContainer" style="height:600px;width:100%" ref="hereMap"></div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
    export default {
         name: "HereMap",
         props: {
            center: Object
            // center object { lat: 40.730610, lng: -73.935242 }
        },
        data() {
            return {
                user : [],
                platform: null,
                apikey: "{AIzaSyAlTnb5lQ2cFPLfl3r-rZ4wxgKuWYeYVv8}"
                // You can get the API KEY from developer.here.com

                
            }
        },

        
        async mounted() {
           console.log('App Mounted');
            if (localStorage.getItem('user'))
                this.user = JSON.parse(localStorage.getItem('user'));

                const platform = new window.H.service.Platform({
                apikey: this.apikey
                });
                this.platform = platform;
                this.initializeHereMap();
        },


        methods: {
            initializeHereMap() { // rendering map

            const mapContainer = this.$refs.hereMap;
            const H = window.H;
            // Obtain the default map types from the platform object
            var maptypes = this.platform.createDefaultLayers();

            // Instantiate (and display) a map object:
            var map = new H.Map(mapContainer, maptypes.vector.normal.map, {
                zoom: 10,
                center: this.center
                // center object { lat: 40.730610, lng: -73.935242 }
            });

            addEventListener("resize", () => map.getViewPort().resize());

            // add behavior control
            new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

            // add UI
            H.ui.UI.createDefault(map, maptypes);
            // End rendering the initial map
            }
        }       
    
    }
</script>



<style scoped>
#map {
  width: 60vw;
  min-width: 100%;
  text-align: center;
  background-color: #ccc;
}
</style>