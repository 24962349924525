<template>
  <div class="conatiner-fluid">
    <my-header></my-header>
    <div class="feature map mt-4">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158857.7281062214!2d-0.24168183442752886!3d51.52877184100476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sin!4v1628939087589!5m2!1sen!2sin"
        style="border: 0; height: 90vh; width: 100%"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
    <div class="task-form mt-4">
      <div class="conatiner-fluid">
        <form class="task-main-form">
          <label class="m-heading">Task For Pickup</label>
          <div class="row">
            <div class="col-md-6 col-lg-6 col-12">
              <div class="input-group input-group-icon">
                <input type="text" name="pname" placeholder="Full Name *" />
                <div class="input-icon"><i class="fa fa-user"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input
                  type="tel"
                  name="pnumber"
                  placeholder="Contact Number *"
                />
                <div class="input-icon"><i class="fa fa-phone"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input
                  type="email"
                  name="pemail"
                  placeholder="Email Adress *"
                />
                <div class="input-icon"><i class="fa fa-envelope"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input type="text" name="porderid" placeholder="Order ID *" />
                <div class="input-icon"><i class="fa fa-cube"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input
                  type="text"
                  name="paddress"
                  placeholder="Pickup Address *"
                />
                <div class="input-icon"><i class="fa fa-map-marker"></i></div>
              </div>
              <label class="label-switch switch-primary pt-3 pb-3">
                <input
                  type="checkbox"
                  class="switch switch-bootstrap status pl-3"
                  name="p-status"
                  id="p-status"
                  value="1"
                />
                <span class="lable"
                  >&#160;&#160;Enable Coordinates For Pickup</span
                ></label
              >
            </div>
            <div class="col-md-6 col-lg-6 col-12">
              <div class="input-group input-group-icon">
                <input
                  type="text"
                  name="platitude"
                  id="p-latitude"
                  class="p-co-ordinates"
                  placeholder="Latitude"
                  disabled
                />
                <div class="input-icon"><i class="fa fa-map-marker"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input
                  type="text"
                  name="plongitude"
                  id="p-longitude"
                  class="p-co-ordinates"
                  placeholder="Longitude"
                  disabled
                />
                <div class="input-icon"><i class="fa fa-map-marker"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input
                  type="text"
                  id="date-time"
                  placeholder="Pickup Before *"
                  name="pdt"
                />
                <div class="input-icon"><i class="fa fa-calendar"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input type="text" name="pdesc" placeholder="Description *" />
                <div class="input-icon"><i class="fa fa-pencil"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input type="file" name="pfile" />
                <div class="input-icon"><i class="fa fa-upload"></i></div>
              </div>
            </div>
          </div>
          <label class="m-heading">Task For Delivery</label>
          <div class="row">
            <div class="col-md-6 col-lg-6 col-12">
              <div class="input-group input-group-icon">
                <input type="text" name="dname" placeholder="Full Name *" />
                <div class="input-icon"><i class="fa fa-user"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input
                  type="tel"
                  name="dnumber"
                  placeholder="Contact Number *"
                />
                <div class="input-icon"><i class="fa fa-phone"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input
                  type="email"
                  name="demail"
                  placeholder="Email Adress *"
                />
                <div class="input-icon"><i class="fa fa-envelope"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input type="text" name="dorderid" placeholder="Order ID *" />
                <div class="input-icon"><i class="fa fa-cube"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input
                  type="text"
                  name="daddress"
                  placeholder="Pickup Address *"
                />
                <div class="input-icon"><i class="fa fa-map-marker"></i></div>
              </div>
              <label class="label-switch switch-primary pt-3 pb-3">
                <input
                  type="checkbox"
                  class="switch switch-bootstrap status pl-3"
                  name="d-status"
                  id="d-status"
                  value="1"
                />
                <span class="lable"
                  >&#160;&#160;Enable Coordinates For Delivery</span
                ></label
              >
            </div>
            <div class="col-md-6 col-lg-6 col-12">
              <div class="input-group input-group-icon">
                <input
                  type="text"
                  name="dlatitude"
                  id="d-latitude"
                  class="d-co-ordinates"
                  placeholder="Latitude"
                  disabled
                />
                <div class="input-icon"><i class="fa fa-map-marker"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input
                  type="text"
                  name="dlongitude"
                  id="d-longitude"
                  class="d-co-ordinates"
                  placeholder="Longitude"
                  disabled
                />
                <div class="input-icon"><i class="fa fa-map-marker"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input
                  type="text"
                  id="date-time"
                  placeholder="Pickup Before *"
                  name="ddt"
                />
                <div class="input-icon"><i class="fa fa-calendar"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input type="text" name="ddesc" placeholder="Description *" />
                <div class="input-icon"><i class="fa fa-pencil"></i></div>
              </div>
              <div class="input-group input-group-icon">
                <input type="file" name="dfile" />
                <div class="input-icon"><i class="fa fa-upload"></i></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-12">
              <button type="submit" name="submit" class="btn create-task-btn">
                Create Task
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.feature.map iframe {
  width: 100%;
  border: 0;
  top: 50px;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(99%);
  -webkit-backface-visibility: hidden;
}

// task form
.task-form {
  background-color: #fff;
  height: 90vh;
  width: 45%;
  top: 50px;
  right: 0;
  position: absolute;
  z-index: 999;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.task-main-form {
  height: 90vh;
  overflow-y: scroll;
  padding: 0 30px 20px 20px;
}
.task-main-form .m-heading {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 15px;
  background-color: #2c3e50;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #b7b7b7;
  border-radius: 3px;
  color: #fff;
}

@media screen and (max-width: 1000px) {
  .task-form {
    background-color: #fff;
    height: 90vh;
    width: 65%;
    top: 50px;
    right: 0;
    position: absolute;
    z-index: 999;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}

@media screen and (max-width: 1500px) {
  .task-form {
    background-color: #fff;
    height: 90vh;
    width: 60%;
    top: 50px;
    right: 0;
    position: absolute;
    z-index: 999;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}

@media screen and (max-width: 999px) {
  .task-form {
    background-color: #fff;
    height: 90vh;
    width: 50%;
    top: 50px;
    right: 0;
    position: absolute;
    z-index: 999;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}

@media screen and (max-width: 900px) {
  .task-form {
    background-color: #fff;
    height: 90vh;
    width: 70%;
    top: 50px;
    right: 0;
    position: absolute;
    z-index: 999;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}

@media screen and (max-width: 650px) {
  .task-form {
    background-color: #fff;
    height: 90vh;
    width: 60%;
    top: 50px;
    right: 0;
    position: absolute;
    z-index: 999;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}

@media screen and (max-width: 500px) {
  .task-form {
    background-color: #fff;
    height: 90vh;
    width: 90%;
    top: 50px;
    right: 0;
    position: absolute;
    z-index: 999;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}

.task-form input,
.task-form input[type="radio"] + label,
.task-form input[type="checkbox"] + label:before,
.task-form select option,
.task-form select {
  width: 100%;
  padding: 1em;
  line-height: 1.4;
  background-color: #dcdcdc;
  border: 1px solid #b7b7b7;
  border-radius: 3px;
  -webkit-transition: 0.35s ease-in-out;
  -moz-transition: 0.35s ease-in-out;
  -o-transition: 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.task-form input[type="radio"] {
  display: none;
}
.task-form input[type="radio"] + label,
.task-form select {
  display: inline-block;
  width: 50%;
  text-align: center;
  float: left;
  border-radius: 0;
}
.task-form input[type="radio"] + label:first-of-type {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.task-form input[type="radio"] + label:last-of-type {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.task-form input[type="radio"] + label i {
  padding-right: 0.4em;
}
.task-form input[type="radio"]:checked + label,
.task-form input:checked + label:before,
.task-form select:focus,
.task-form select:active {
  background-color: #000;
  color: #fff;
}
.task-form input:hover,
.task-form input:focus {
  background-color: #fff;
}
.task-form input[type="checkbox"] {
  display: none;
}
.task-form input[type="checkbox"] + label {
  position: relative;
  display: block;
  padding-left: 1.6em;
}
.task-form input[type="checkbox"] + label:before {
  position: absolute;
  top: 0.2em;
  left: 0;
  display: block;
  width: 1em;
  height: 1em;
  padding: 0;
  content: "";
}
.task-form input[type="checkbox"] + label:after {
  position: absolute;
  top: 0.45em;
  left: 0.2em;
  font-size: 0.8em;
  color: #fff;
  opacity: 0;
  font-family: FontAwesome;
  content: "\f00c";
}
.task-form input:checked + label:after {
  opacity: 1;
}
.task-form select {
  height: 3.4em;
  line-height: 2;
}
.task-form select:first-of-type {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.task-form select:last-of-type {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.task-form select:focus,
.task-form select:active {
  outline: 0;
}
.task-form select option {
  background-color: #000;
  color: #fff;
}
.task-form .input-group {
  margin-bottom: 1em;
  zoom: 1;
}
.task-form.input-group:before,
.task-form .input-group:after {
  content: "";
  display: table;
}
.task-form .input-group:after {
  clear: both;
}
.task-form .input-group-icon {
  position: relative;
}
.task-form .input-group-icon input {
  padding-left: 4.4em;
}
.task-form .input-group-icon .input-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 3.4em;
  height: 3.4em;
  line-height: 3.4em;
  text-align: center;
  pointer-events: none;
}
.task-form .input-group-icon .input-icon:after {
  position: absolute;
  top: 0.6em;
  bottom: 0.6em;
  left: 3.4em;
  display: block;
  border-right: 1px solid #b7b7b7;
  content: "";
  -webkit-transition: 0.35s ease-in-out;
  -moz-transition: 0.35s ease-in-out;
  -o-transition: 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.task-form .input-group-icon .input-icon i {
  -webkit-transition: 0.35s ease-in-out;
  -moz-transition: 0.35s ease-in-out;
  -o-transition: 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.task-form .conatiner-fluid {
  // max-width: 38em;
  // padding: 1em 3em 2em 3em;
  padding: 20px 10px 0 20px;
  margin: 0em auto;
  background-color: #fff;
  border-radius: 4.2px;
}
.task-form .row {
  zoom: 1;
}
.task-form .row:before,
.task-form .row:after {
  content: "";
  display: table;
}
.task-form .row:after {
  clear: both;
}
.task-form .col-half {
  padding-right: 10px;
  float: left;
  width: 50%;
}
.task-form .col-half:last-of-type {
  padding-right: 0;
}
.task-form .col-third {
  padding-right: 10px;
  float: left;
  width: 33.33333333%;
}
.task-form .col-third:last-of-type {
  padding-right: 0;
}
@media only screen and (max-width: 540px) {
  .task-form .col-half {
    width: 100%;
    padding-right: 0;
  }
}

// file input
input[type="file"] {
  padding: 11px 0 0 0;
  cursor: pointer;
  overflow: hidden;
  height: 53px;
  color: transparent;
  vertical-align: middle;
}

input[type="file"]:before {
  content: "Select Image File";
  text-align: center;
  color: #757575;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

// switch
.switch {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.switch + .lable {
  color: #757575;
  position: relative;
  display: inline-block;
  margin: 0;
  line-height: 20px;
  min-height: 18px;
  min-width: 18px;
  font-weight: normal;
  cursor: pointer;
}
.switch + .lable::before {
  cursor: pointer;
  font-family: fontAwesome;
  font-weight: normal;
  font-size: 12px;
  color: #32a3ce;
  content: "\a0";
  background-color: #fafafa;
  border: 1px solid #c8c8c8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 0;
  display: inline-block;
  text-align: center;
  height: 16px;
  line-height: 14px;
  min-width: 16px;
  margin-right: 1px;
  position: relative;
  top: -1px;
}
.switch:checked + .lable::before {
  display: inline-block;
  content: "\f00c";
  background-color: #f5f8fc;
  border-color: #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
}
/* CSS3 on/off switches */
.switch + .lable {
  min-height: 24px;
}
.switch + .lable::before {
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  height: 20px;
  overflow: hidden;
  border-radius: 3px;
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #ccc;
  text-align: left;
  float: left;
  padding: 0;
  width: 52px;
  text-indent: -21px;
  margin-right: 0;
  -webkit-transition: text-indent 0.3s ease;
  -o-transition: text-indent 0.3s ease;
  transition: text-indent 0.3s ease;
  top: auto;
}
.switch.switch-bootstrap + .lable::before {
  font-family: FontAwesome;
  content: "\f00d";
  box-shadow: none;
  border-width: 0;
  font-size: 10px;
  background-color: #dcdcdc;
  color: #3e4e5e;
  width: 42px;
  height: 23px;
  line-height: 21px;
  text-indent: 28px;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  border: 1px solid #b7b7b7;
}
.switch.switch-bootstrap + .lable::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 3px;
  border-radius: 3px;
  width: 15px;
  height: 15px;
  text-align: center;
  background-color: #3e4e5e;
  border: 4px solid #3e4e5e;
  -webkit-transition: left 0.2s ease;
  -o-transition: left 0.2s ease;
  transition: left 0.2s ease;
}
.switch.switch-bootstrap:checked + .lable::before {
  content: "\f00c";
  text-indent: 6px;
  color: #2c3e50;
  border-color: #b7b7b7;
}
.switch-primary > .switch.switch-bootstrap:checked + .lable::before {
  background-color: #dcdcdc;
}
.switch.switch-bootstrap:checked + .lable::after {
  left: 22px;
  background-color: #2c3e50;
  border: 4px solid #2c3e50;
  text-shadow: 0 -1px 0 rgba(0, 200, 0, 0.25);
}
#p-latitude,
#p-longitude,
#d-latitude,
#d-longitude {
  border: 1px solid #ff5d5d;
}

.btn.create-task-btn {
  background-color: #3e4e5e;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 15px 20px;
  margin-top: 20px;
  border-radius: 3px;
}
.btn.create-task-btn:hover {
  background-color: #3e4e5e;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
</style>

<script>
$(document).ready(function () {
  $("#date-time").focus(function () {
    $(this).attr({ type: "datetime-local" });
  });
  $("#p-status").click(function () {
    if (this.checked) {
      $(".p-co-ordinates").prop("disabled", false);
      $("#p-latitude").css("border", "1px solid #b7b7b7");
      $("#p-longitude").css("border", "1px solid #b7b7b7");
    } else {
      $(".p-co-ordinates").prop("disabled", true);
      document.getElementById("p-longitude").value = "";
      document.getElementById("p-latitude").value = "";
      $("#p-latitude").css("border", "1px solid #ff5d5d");
      $("#p-longitude").css("border", "1px solid #ff5d5d");
    }
  });
  $("#d-status").click(function () {
    if (this.checked) {
      $(".d-co-ordinates").prop("disabled", false);
      $("#d-latitude").css("border", "1px solid #b7b7b7");
      $("#d-longitude").css("border", "1px solid #b7b7b7");
    } else {
      $(".d-co-ordinates").prop("disabled", true);
      document.getElementById("d-longitude").value = "";
      document.getElementById("d-latitude").value = "";
      $("#d-latitude").css("border", "1px solid #ff5d5d");
      $("#d-longitude").css("border", "1px solid #ff5d5d");
    }
  });
});
</script>