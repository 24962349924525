<template>
    <div class="container-fluid py-1 px-5" style="margin-top: 80px">
        <my-header></my-header>
        <div class="card radius shadow-lg">            
           <div class="card-header">
              <span class="card-title h5">Task List</span>
              <button @click="add()" class="btn add-btn float-right text-white">
                Create New task
              </button>
              <table id="example" class="table table-striped table-bordered mt-5" style="width: 100%">
                <thead>
                    <tr>
                    <th class="cb">
                      <input type="checkbox" onclick="checkAll(this)" />
                    </th>
                    <th>TASK ID</th>
                    <th>ORDER ID</th>
                    <th>DRIVER DETAILS</th>
                    <th>PICKUP DETAILS</th>
                    <th>DELIVERY DETAILS</th>
                    <th>START BEFORE</th>
                    <th>COMPLETE BEFORE</th>
                    <th>REVIEW RATING</th>
                    <th>TASK STATUS</th>
                    <th>ACTION</th>
                    </tr>
                </thead>
                <tbody>
                    <tr :key="task.id" v-for="task in taskList">
                        <td><input type="checkbox" name="" /></td>
                        <td>{{ task.id }}</td>
                        <td>{{ task.order_id }}</td>
                        <td>{{ task.name}} {{ task.phone}} {{ task.email}}</td>
                        <td>{{ task.pickup_address }}</td>
                        <td>{{ task.del_pickup_address }}</td>
                        <td>{{ task.pickup_before }}</td>
                        <td>{{ task.pickup_before }}</td>
                        <td>{{ task.rating }}</td>
                        <td>{{ task.status }}</td>
                        <td>
                          <span><a href="#" @click="edit(task)" data-toggle="tooltip" data-placement="top" title="Edit Task"><i data-toggle="tooltip" data-placement="top" title="Edit Task" class="fa fa-pencil px-1"></i></a></span>
                          <span><a href="#"  @click="deleteDoc(task)" data-toggle="tooltip" data-placement="top" title="Delect Task"><i data-toggle="tooltip" data-placement="top" title="Delect Task" class="fa fa-trash px-1"></i></a></span>
                          <span><a href="#" data-toggle="tooltip" data-placement="top" title="Assign Task"><i data-toggle="tooltip" data-placement="top" title="Assign Task" class="fa fa-share px-1"></i></a></span>
                        </td>                        
                    </tr>
                </tbody>
              </table>   
            </div>
        </div>
    </div>
</template>
<script>
    import { axios } from "@/axios";
    
    export default {
      created() {
        this.get();
      },
      data() {
        return {
          taskList: [],
          // user:[],
        };
      },
      methods: {
        get() {
          let user = JSON.parse(localStorage.getItem('user'));
          console.log(user.id);
          axios.post(`/taskList/${user.id}`).
          then(response => {  
            this.taskList = response.data.data;  
            // console.log('then');
            // console.log(response.data);  
          })  
          .catch(e => {  
            // console.log('catch');
            console.log(e);  
          });
          // then(({ response }) => {
          //   console.log(response);
          //   this.tasks = response.data.data;
          // });
        },
        add() {
          this.$router.push({ name: "task.form" });
        },
        edit(task) {
          this.$router.push({ name: "task.edit", params: { id: task.id } });
        },
        deleteDoc(task) {
          axios.delete(`tasks/${task.id}`).then(() => this.get());
        },
        parseDate(date) {
          const parsedDate = new Date(date);
          return parsedDate.toISOString().slice(0, 10);
        },
      },
    };
  
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();   
      $("#example").DataTable({
        aLengthMenu: [
          [5, 10, 25, -1],
          [5, 10, 25, "All"],
        ],
        iDisplayLength: 5,
      });
    });
</script>