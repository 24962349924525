<template>
    <div class="container-fluid">
        
        <div class="sidebar-group">
            <!-- BEGIN: User Menu -->
            <div class="sidebar" id="user-menu">
                <div class="py-4 text-center" data-backround-image="../assets/media/image/image1.jpg">
                    <figure class="avatar avatar-lg mb-3 border-0">
                        <img src="../assets/media/image/user/women_avatar1.jpg" class="rounded-circle" alt="image">
                    </figure>
                    <h5 class="d-flex align-items-center justify-content-center">{{ user.name }}</h5>
                    <div>
                        Balance: <strong>$500</strong>
                    </div>
                </div>
                <div class="card mb-0 card-body shadow-none">
                    <div class="mb-4">
                        <div class="list-group list-group-flush">
                            <a href="/profile" class="list-group-item p-l-r-0">Profile</a>
                            <a href="#" class="list-group-item p-l-r-0 d-flex">Followers</a>
                            <a href="#" class="list-group-item p-l-r-0 d-flex">
                                Inbox <span class="badge badge-danger ml-auto">2</span>
                            </a>
                            <a href="#" class="list-group-item p-l-r-0">Billing</a>
                            <a href="#" class="list-group-item p-l-r-0" data-sidebar-target="#settings">Settings</a>
                            <a href="login.html" class="list-group-item p-l-r-0 text-danger" data-sidebar-target="#settings">Sign Out!</a>
                        </div>
                    </div>
                    <div class="mb-4">
                        <h6 class="d-flex justify-content-between">
                            Completed Tasks
                            <span class="float-right">%68</span>
                        </h6>
                        <div class="progress" style="height:5px;">
                            <div class="progress-bar bg-secondary" role="progressbar" style="width: 68%;"
                                aria-valuenow="68"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="mb-4">
                        <h6 class="d-flex justify-content-between">
                            Storage
                            <span>%25</span>
                        </h6>
                        <div class="progress" style="height: 5px;">
                            <div class="progress-bar bg-info" role="progressbar" style="width: 40%;" aria-valuenow="40"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="mb-4">
                        <h6>About</h6>
                        <p class="text-muted mb-0">I love reading, traveling and discovering new things. You need to be happy in
                            life.</p>
                    </div>
                    <div class="mb-4">
                        <h6>City</h6>
                        <p class="text-muted mb-0">Germany / Berlin</p>
                    </div>
                    <div class="mb-4">
                        <h6>Social Links</h6>
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <a href="#" class="btn btn-sm btn-floating btn-facebook">
                                    <i class="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#" class="btn btn-sm btn-floating btn-twitter">
                                    <i class="fa fa-twitter"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#" class="btn btn-sm btn-floating btn-dribbble">
                                    <i class="fa fa-dribbble"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#" class="btn btn-sm btn-floating btn-whatsapp">
                                    <i class="fa fa-whatsapp"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#" class="btn btn-sm btn-floating btn-linkedin">
                                    <i class="fa fa-linkedin"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- END: User Menu -->

            <!-- BEGIN: Settings -->
            <div class="sidebar" id="settings">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Settings</h6>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item pl-0 pr-0">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch1" checked>
                                    <label class="custom-control-label" for="customSwitch1">Allow notifications.</label>
                                </div>
                            </li>
                            <li class="list-group-item pl-0 pr-0">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch2">
                                    <label class="custom-control-label" for="customSwitch2">Hide user requests</label>
                                </div>
                            </li>
                            <li class="list-group-item pl-0 pr-0">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch3" checked>
                                    <label class="custom-control-label" for="customSwitch3">Speed up demands</label>
                                </div>
                            </li>
                            <li class="list-group-item pl-0 pr-0">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch4" checked>
                                    <label class="custom-control-label" for="customSwitch4">Hide menus</label>
                                </div>
                            </li>
                            <li class="list-group-item pl-0 pr-0">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch5">
                                    <label class="custom-control-label" for="customSwitch5">Remember next visits</label>
                                </div>
                            </li>
                            <li class="list-group-item pl-0 pr-0">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch6">
                                    <label class="custom-control-label" for="customSwitch6">Enable report
                                        generation.</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- END: Settings -->

        </div>

            <!-- begin::header -->
        <div class="header d-print-none">

            <div class="header-left">
                <div style="color:#fff" class="navigation-toggler">
                    <a href="#" data-action="navigation-toggler">
                        <i data-feather="menu"></i> <i class="fa fa-user"></i>
                    </a>
                </div>
                <div class="header-logo">                    	
                    <a href="/task/create">
                      <h4>PIKADRO</h4>
                    </a>
                </div>
            </div>

            <div class="header-body">
                <div class="header-body-left">
                    <div class="page-title">
                        <ul>
                            <li><a href="">MAP</a></li>
                            <li><a href="/tasklist">LIST</a></li>
                            <li><input type="date" name="" id=""></li>
                        </ul>
                    </div>
                </div>
                <div class="header-body-right">
                    <ul class="navbar-nav">                    
                        <!-- end::settings -->
                        <!-- begin::user menu -->
                        <li>
                            <notification-item v-for="unread in unreadNotifications" :key="unread.id" :unread="unread"></notification-item>

                            <button style="background:#fff;border-radius:20px;" class="mr-3"><a href="/notificationlist"><i class="fa fa-bell"></i>{{ count.length }}</a></button>
                        </li>
                        <li>
                            <button style="background:#fff;border-radius:20px;"><a href="/">Logout</a></button>
                        </li>

                        <li>
                            <button style="background:#fff;border-radius:20px;"><a href="task/create">Create New Task</a></button>
                        </li>
                        <li class="nav-item dropdown">
                            <a href="#" class="nav-link" title="User menu" data-sidebar-target="#user-menu">
                                <span style="color:#fff" class="mr-2 d-sm-inline d-none">{{ user.name }}</span>
                                <figure class="avatar avatar-sm">
                                    <img src="../assets/media/image/user/women_avatar1.jpg" class="rounded-circle"
                                        alt="avatar">
                                </figure>
                            </a>                                                 
                        </li>
                        <!-- end::user menu -->
                    </ul>
                    <!-- begin::mobile header toggler -->
                    <ul class="navbar-nav d-flex align-items-center">
                        <li class="nav-item header-toggler">
                            <a href="#" class="nav-link">
                                <i data-feather="arrow-down"></i>
                            </a>
                        </li>
                    </ul>
                    <!-- end::mobile header toggler -->
                </div>
            </div>
        </div>
       
    </div>
</template>
<script>
import { axios } from "@/axios";
    export default {
        data() {
            return {
                user : [],
                count: [],
            }
        },
        mounted() {
        //    console.log('App Mounted');
            if (localStorage.getItem('user'))
                this.user = JSON.parse(localStorage.getItem('user'));


            let user = JSON.parse(localStorage.getItem('user'));
            // console.log(user.id);
            axios.post(`/notificaionList/${user.id}`).
            then(response => {  
                this.count = response.data.data;  
                // console.log('then');
                console.log(response.data.data);  
            })  
            .catch(e => {  
                // console.log('catch');
                console.log(e);  
            });
            
        },
        
        // methods:{
        //     get() {
        //         let user = JSON.parse(localStorage.getItem('user'));
        //         // console.log(user.id);
        //         axios.post(`/notificaionList/${user.id}`).
        //         then(response => {  
        //             this.count = response.data.data;  
        //             // console.log('then');
        //             console.log(response.data.data);  
        //         })  
        //         .catch(e => {  
        //             // console.log('catch');
        //             console.log(e);  
        //         });
        //         // then(({ response }) => {
        //         //   console.log(response);
        //         //   this.notifications = response.data.data;
        //         // });
        //     },
        // },
    }
</script>

<style scoped>
.header{
    background: #000 !important;
}
a.header {
    color: #fff !important;
}
.header-logo h4, .page-title h4{
    color: #fff;
}
.page-title ul li a {
    color:#000 !important;
}
.page-title ul li{
    display: inline !important;
    background: #fff;
    padding: 8px 15px;
    border: 1px solid;
}
</style>